

import React, { useState, useEffect, } from 'react'
import Footer from '../Footer';
import l3 from "../Assets/l3.png";
import con from "../Assets/contact.svg";

function Contact() {

    const [button,setButton] = useState(true);


   

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
},[]);
  window.addEventListener('resize',showButton);


  return (
   <div>
     <div style={{paddingTop:200,display:'flex',flexDirection:'column',alignItems:'center',paddingBottom:button?'12vw':75,backgroundColor:'#f2f2f1',fontFamily:'Inter',}}>
     <img  style={{height:'10vh',width:'100%'}} src={con}/>
        <text style={{width:button?'50vw':'70vw',textAlign:'center',paddingTop:button?'3vh':'2vh',fontSize:14,color:'#82806d'}}>If you have any questions about TDS® or would like us to be involved with your next project, please contact us via email or phone.</text>
      <div style={{display:'flex',flexDirection:button?'row':'column',paddingTop:button?120:80,textAlign:button?'':'center'}}>
      <div style={{display:'flex',flexDirection:'column',color:'grey',fontSize:12,}}>
      <a href='tel:+919030777222'  style={{color:"grey"}}>+91 9030777222</a>    
      <a href='tel:+917337077746' style={{color:"grey"}} >+91 7337077746</a>    
      <a href="mailto:info@brownprofiles.com"  style={{color:"grey"}}>info@thedoorstory.co</a>
      </div>
      <div style={{display:'flex',flexDirection:'column',color:'grey',fontSize:12,paddingLeft:button?175:0,paddingTop:button?0:30}}>
      <text>Viswakarma ML Furniture</text>
      <text>Plot No. CFC 1/A, Road No. 10, Kattedan,</text>
      <text>Industrial Area, Hyderabad, T.S. 500077</text>
      </div>
      <div style={{display:'flex',flexDirection:'column',color:'grey',fontSize:12,paddingLeft:button?175:0,paddingTop:button?0:30}}>
      <a href='https://goo.gl/maps/K7TSFT3FKZAZaHuA9'  target="_blank" style={{color:"grey"}}> Maps</a>
      <a href='https://www.instagram.com/thedoor.story/'  target="_blank" style={{color:"grey"}}>Instagram</a>
      <text>Facebook</text>

      </div>
      </div>
    </div>
    <img  style={{height:button?'100vh':'40vh',width:'100%'}} src={l3}/>
<Footer/>
   </div>
  );
}

export default Contact;