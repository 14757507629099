import React, { useState, useEffect, } from 'react'
import '../App.css';

import { BsArrowDown } from "react-icons/bs";
import bg from "../Assets/bg3.png";
import home from "../Assets/home.png";
import h1 from "../Assets/h1.png";
import l12 from "../Assets/l12.jpg";
import l11 from "../Assets/l11.png";
import l13 from "../Assets/l13.jpg";
import l14 from "../Assets/l14.jpg";
import l18 from "../Assets/l18.jpg";
import l16 from "../Assets/l16.jpg";
import l17 from "../Assets/l17.jpg";
import l8 from "../Assets/l8.jpg";
import wood from "../Assets/wood.png";
import { Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../Footer';

const Projects = props => {


    const [button,setButton] = useState(true);

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
},[]);
  window.addEventListener('resize',showButton);


  return (
    <div  className='project'>
<div className='section' style={{display:'flex',flexDirection:'column',fontFamily:'Montserrat',paddingTop:'50vh',alignItems:'center'}}>
        <text style={{fontSize:40,}}><span style={{color:'lightGrey'}}>OUR</span> WORK</text>
        <div style={{display:'flex',marginTop:button?'20vh':'8vh'}}>
        <BsArrowDown style={{height:50,width:25}}/>
        </div>
        </div>
    
    <div className='section'>
      <div style={{display:'flex',flexDirection:button?'row':'column',borderTop:'1px solid grey',alignItems:'center'}}>
        <img  style={{height:button?'65vh':'30vh',width:button?'48vw':'92%',marginLeft:button?'5vw':'',marginTop:button?'20vh':'25vh'}} src={l8}/>
      <div style={{display:'flex',flexDirection:'column',paddingLeft:button?75:'4%',marginTop:button?'20vh':'5vh'}}>
        <h3 style={{fontFamily:'serif'}}>Sireniti House</h3>
        <text style={{width:button?'30vw':'',paddingTop:15,lineHeight:1.7,paddingRight:20}}>A door that transcends mere functionality to become an integral part of the structure, making a profound architectural statement. Our <strong>8'x16'</strong> colossal door effortlessly connects the interiors to the beautiful surroundings.</text>
      </div>
      </div>
      </div>
      <div className='section'>
      <div style={{display:'flex',flexDirection:button?'row':'column',borderTop:'1px solid grey',alignItems:'center'}}>
        <img  style={{height:button?'65vh':'30vh',width:button?'48vw':'92%',marginLeft:button?'5vw':'',marginTop:button?'20vh':'25vh'}}  src={l12}/>
        <div style={{display:'flex',flexDirection:'column',paddingLeft:button?75:'4%',marginTop:button?'20vh':'5vh'}}>
        <h3 style={{fontFamily:'serif'}}>The Peacock Door</h3>
        <text style={{width:button?'30vw':'',paddingTop:20,lineHeight:1.7,paddingRight:20}}>Crafted from the finest Burma teak, our peacock door stands an imposing 14 feet in height and 8 feet in width, embodying both grandeur and grace. It is 3 inches thick and exudes durability and solidity.</text>
      </div>
      </div>
      </div>
      <div className='section'>
      <div style={{display:'flex',flexDirection:button?'row':'column',borderTop:'1px solid grey',alignItems:'center'}}>
        <img  style={{height:button?'65vh':'30vh',width:button?'48vw':'92%',marginLeft:button?'5vw':'',marginTop:button?'20vh':'25vh'}}  src={l11}/>
        <div style={{display:'flex',flexDirection:'column',paddingLeft:button?75:'4%',marginTop:button?'20vh':'5vh'}}>
        <h3 style={{fontFamily:'serif'}}>Ethereal Elegance</h3>
        <text style={{width:button?'30vw':'',paddingTop:20,lineHeight:1.7,paddingRight:20}}>A masterpiece inspired from traditional Jaali wall, allowing a delicate interplay of light and shadow. It serves as a visual barrier while maintaining a sense of openness, allowing glimpses into the world beyond.</text>
      </div>
      </div>
      </div>
      <div className='section'>
      <div style={{display:'flex',flexDirection:button?'row':'column',borderTop:'1px solid grey',alignItems:'center'}}>
        <img  style={{height:button?'65vh':'30vh',width:button?'48vw':'92%',marginLeft:button?'5vw':'',marginTop:button?'20vh':'25vh'}}  src={l13}/>
        <div style={{display:'flex',flexDirection:'column',paddingLeft:button?75:'4%',marginTop:button?'20vh':'5vh'}}>
        <h3 style={{fontFamily:'serif'}}>Minimal Essence</h3>
        <text style={{width:button?'30vw':'',paddingTop:20,lineHeight:1.7,paddingRight:20}}>An embodiment of understated luxury. The minimalist approach allows the door to seamlessly integrate into any space, serving as a versatile backdrop that lets the surrounding design elements shine.</text>
      </div>
      </div>
      </div>
      <div className='section'>
      <div style={{display:'flex',flexDirection:button?'row':'column',borderTop:'1px solid grey',alignItems:'center'}}>
        <img  style={{height:button?'65vh':'30vh',width:button?'48vw':'92%',marginLeft:button?'5vw':'',marginTop:button?'20vh':'25vh'}} src={l14}/>
        <div style={{display:'flex',flexDirection:'column',paddingLeft:button?75:'4%',marginTop:button?'20vh':'5vh'}}>
        <h3 style={{fontFamily:'serif'}}>Anabia</h3>
        <text style={{width:button?'30vw':'',paddingTop:20,lineHeight:1.7,paddingRight:20}}>The villa's security door stands as a reminder that even in the realm of functionality, art has a place, and beauty can be found in the most ordinary of spaces.</text>
      </div>
      </div>
      </div>
      <div className='section'>
      <div style={{display:'flex',flexDirection:button?'row':'column',borderTop:'1px solid grey',alignItems:'center'}}>
        <img  style={{height:button?'65vh':'30vh',width:button?'48vw':'92%',marginLeft:button?'5vw':'',marginTop:button?'20vh':'25vh'}} src={l16}/>
        <div style={{display:'flex',flexDirection:'column',paddingLeft:button?75:'4%',marginTop:button?'20vh':'5vh'}}>
        <h3 style={{fontFamily:'serif'}}>Timeless Beauty</h3>
        <text style={{width:button?'30vw':'',paddingTop:20,lineHeight:1.7,paddingRight:20}}>A statement of refined taste, a door that complements any architectural style with effortless grace. The choice of premium materials ensures that every touch is a tactile delight.</text>
      </div>
      </div>
      </div>
      <div className='section'>
      <div style={{display:'flex',flexDirection:button?'row':'column',borderTop:'1px solid grey',alignItems:'center'}}>
        <img  style={{height:button?'65vh':'30vh',width:button?'48vw':'92%',marginLeft:button?'5vw':'',marginTop:button?'20vh':'25vh'}} src={l17}/>
        <div style={{display:'flex',flexDirection:'column',paddingLeft:button?75:'4%',marginTop:button?'20vh':'5vh'}}>
        <h3 style={{fontFamily:'serif'}}>Classical Grandeur</h3>
        <text style={{width:button?'30vw':'',paddingTop:20,lineHeight:1.7,paddingRight:20}}>A perfect blend of premium wood and craftsmanship,  this door design seamlessly integrates into traditional architecture, elevating the entrance to a new level of elegance.</text>
      </div>
      </div>
      </div>
      <div className='section'>
      <div style={{display:'flex',flexDirection:button?'row':'column',borderTop:'1px solid grey',alignItems:'center'}}>
        <img  style={{height:button?'65vh':'30vh',width:button?'48vw':'92%',marginLeft:button?'5vw':'',marginTop:button?'20vh':'25vh'}} src={l18}/>
        <div style={{display:'flex',flexDirection:'column',paddingLeft:button?75:'4%',marginTop:button?'20vh':'5vh'}}>
        <h3 style={{fontFamily:'serif'}}>Vintage Allure</h3>
        <text style={{width:button?'30vw':'',paddingTop:20,lineHeight:1.7,paddingRight:20}}>This classical wooden door embodies timeless beauty. Its warm, natural hues not only evoke a sense of elegance but also stand as a testament to the enduring allure of traditional design.</text>
      </div>
      </div>
      </div>
      <div className='section'>
      <div style={{display:'flex',flexDirection:'column',fontFamily:'Montserrat',paddingTop:'40vh',alignItems:'center',textAlign:'center'}}>
        <h3 style={{fontFamily:'serif'}}>Create a new story</h3>
        <text style={{width:button?'50vw':'80vw',paddingTop:20,lineHeight:1.7}}>Our doors whisper tales of tradition and modernity, showcasing the rich heritage of timeless materials like wood, the artistry of skilled hands, and the innovation of contemporary design.</text>
        <a href='/contact' className='endtext'>Contact Us</a>
      </div>
      </div>
    </div>
  );
}

export default Projects;