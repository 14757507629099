import './Footer.css';
import React, { useState, useEffect, } from 'react'
import logo1 from "./Assets/logo1.png";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { Button } from 'react-bootstrap';

const Footer = () => {
  const [isDesktop, setIsDesktop] = useState(true);

  const handleResize = () => {
      if (window.innerWidth <= 960) {
          setIsDesktop(false);
      } else {
          setIsDesktop(true);
      }
  }

  useEffect(() => {
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  return (
      
          <div className={isDesktop?'footer':'mobile'}>
            <div style={{fontSize:isDesktop?36:30,fontWeight:'bold',width:isDesktop?'40%':'',lineHeight:1.25,fontFamily:'Inter',color:'#c2b19c'}}>
            <text>You can help<br></br> shape the future.</text>
            </div>
            <div className={isDesktop?'main':'foot'}>
           <div style={{width:isDesktop?'20%':'',display:'flex',flexDirection:isDesktop?'column':'row',justifyContent:isDesktop?'end':'space-between',marginTop:isDesktop?'':50}}>
              <img  style={{height:20, width:50,marginBottom:10}} src={logo1}></img>
                <text style={{fontSize:14}}>Copyright © {new Date().getFullYear()}</text> 
              <text style={{fontSize:13.5}}>Redefining Homes.</text></div>
              <div style={{width:isDesktop?'70%':'',display:'flex',flexDirection:'column',marginTop:isDesktop?0:40,}}>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'flex-end'}}>
                    <div style={{display:'flex',flexDirection:isDesktop?'column':'row'}}>
                    <div style={{display:'flex',flexDirection:isDesktop?'row':'column'}}>
                        <a className={isDesktop?'menu':'menu foot'} href='/'> Home</a>
                        <a className={isDesktop?'menu':'menu foot'} href='/about'>About Us</a>
                        <a className={isDesktop?'menu':'menu foot'} href='/'>Our Mission</a>
                    </div>
                    <div style={{display:'flex',flexDirection:isDesktop?'row':'column',marginTop:isDesktop?20:''}}>
                    <a className={isDesktop?'menu':'menu foot'} href='/projects'>Our Work</a>
                    <a className={isDesktop?'menu':'menu foot'} href='/contact'>Contact</a>
                    <a className={isDesktop?'menu':'menu foot'} href='/'>Process</a>
                    </div>
                    </div>
                    {isDesktop?<a href='/contact' style={{backgroundColor:'#c2b19c',height:30,width:100,borderRadius:5,textAlign:'center',color:'#383736',fontWeight:'bold',fontSize:11,paddingTop:7,textDecoration:'none'}}>CONTACT US</a>:''}
                    </div>
                  <div style={{borderTop:'2px solid white',marginTop:isDesktop?40:60}}></div>
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',marginTop:isDesktop?40:30}}>
                  <FaInstagram style={{cursor:'pointer'}} onClick={()=>{window.open('https://www.instagram.com/thedoor.story/')}}   className='icon'/>
                  <FaFacebookF className='icon'/>
                  <FaLinkedin className='icon'/>
                  </div>
                  
              </div>
    </div>
    </div>
  
  );
};

export default Footer;