

import React, { useState, useEffect, } from 'react'
import d1 from "../Assets/d1.jpg";
import './About.css';
import l5 from "../Assets/l5.jpg";
import l2 from "../Assets/l2.jpg";
import Footer from '../Footer';

function About() {

    const [button,setButton] = useState(true);


   

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
},[]);
  window.addEventListener('resize',showButton);


  return (
   <div>
   <div className='about'>
    <div style={{display:'flex',flexDirection:'column',paddingLeft:button?'30vw':'15vw'}}>
   <text style={{fontSize:button?20:22,lineHeight:button?1.2:1.4,fontWeight:600}}>Redefining the<br></br> essence of doors</text>
   <text style={{paddingTop:button?8:10}}>Lets craft your door story.</text>
   </div>
  </div>
  <div style={{display:'flex',flexDirection:button?'row':'column',padding:button?'15vh':'4vh',}}>
      <text style={{fontSize:24,width:button?'20vw':'',paddingTop:button?'':30}}>About us</text>
      <div style={{display:'flex',flexDirection:'column',marginLeft:button?'25vh':''}}>
        <text style={{color:'gray',marginTop:10}}>At The Door Story, we take pride in being a leading force in the door manufacturing industry. With a commitment to excellence and a passion for innovation, we have been serving our customers with top-notch doors that not only enhance the aesthetics of spaces but also provide security and durability.</text>
      </div>
    </div>
    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',alignItems:'center',textAlign:'center',paddingTop:button?'10vh':'4vh',paddingBottom:button?'20vh':'10vh',fontFamily:'Inter'}}>
<div style={{display:'flex',flexDirection:'column',width:button?'':'25vw'}}>
  <p style={{fontSize:button?40:30}}>20+</p>
  <p >Years Of Experience</p>
</div>

<div style={{display:'flex',flexDirection:'column',width:button?'':'25vw'}}>
<p style={{fontSize:button?40:30}}>160+</p>
  <p>Finished Projects</p>
</div>
<div style={{display:'flex',flexDirection:'column',width:button?'':'25vw'}}>
<p style={{fontSize:button?40:30}}>500+</p>
  <p style={{}}>Stories Created</p>
</div>

</div>
<img  style={{height:button?'95vh':'25vh',width:'100%'}} src={l2}/>
    <div style={{display:'flex',flexDirection:'row',fontFamily:'Montserrat',backgroundColor:'#f2f2f1',height:button?'100vh':'60vh',alignItems:'center',padding:button?'15vh':'4vh'}}>
      <div style={{display:'flex',flexDirection:'column',marginLeft:button?'10vw':'',fontWeight:600}}>

      <text style={{color:'#1d1c18',fontSize:18,paddingBottom:button?'2vw':'4vw',paddingRight:button?125:25,lineHeight:1.7}}>In a world where mass production prevails, we take pride in our hands-on approach. From the initial design sketches to the final finishing touches, every step is infused with the passion and expertise of our craftsmen.</text>
      <text style={{fontSize:16,textDecoration:'underline'}}>Learn more about our process </text>
      </div>
    
    </div>
<Footer/>
   </div>
  );
}

export default About;