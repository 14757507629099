import React, { useState, useEffect, } from 'react'
import '../App.css';
import { BsArrowRight } from "react-icons/bs";
import bg from "../Assets/bg3.png";

import h1 from "../Assets/h1.png";
import td1 from "../Assets/td1.png";
import td2 from "../Assets/td2.png";
import td3 from "../Assets/td3.png";
import td4 from "../Assets/td4.png";
import l11 from "../Assets/l11.png";
import l5 from "../Assets/l5.jpg";
import l4 from "../Assets/l4.png";
import l9 from "../Assets/l9.png";
import l8 from "../Assets/l8.jpg";
import l7 from "../Assets/l7.jpg";
import wood from "../Assets/wood.png";
import { Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../Footer';
function Home() {


    const [button,setButton] = useState(true);

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
},[]);
  window.addEventListener('resize',showButton);


  return (
    <div>
      <div style={{display:'flex',width:'100%',height:button?'100vh':'',alignItems:'center',backgroundColor:button?'#373c37':'',paddingLeft:button?'30vh':'',paddingRight:button?'30vh':'',justifyContent:button?'space-between':'',paddingTop:button?'10vh':'',flexDirection:button?'row':'column-reverse'}}>
      <div style={{display:'flex',textAlign:button?'left':'center',flexDirection:'column',fontFamily:'Montserrat',width:button?'50%':'80%',color:button?'#f2f2f1':'black',marginTop:button?'':'10vh',marginBottom:button?'':'10vh',padding:20,}}>
    <h1 style={{fontSize:button?24:30}}>Quality Doors for Every Space</h1>
    <text style={{paddingTop:button?10:25,fontSize:button?14:18,lineHeight:1.7,paddingRight:button?100:'',color:button?'#fafafa90':'grey'}}>From the grandeur of intricate designs to the sheer magnitude of our creations, each door is a masterpiece in itself.</text>
  </div>
  <img  style={{height:button?'30vw':'50vh',width:button?'30vw':'100%'}} src={l7}/>
      </div>
      <div  style={{display:'flex',flexDirection:'column',fontFamily:'Montserrat',backgroundColor:'#f2f2f1'}}>
   {button? <div style={{marginTop:'12vh'}}>
        <text style={{fontSize:40,marginLeft:'5vw',color:'black',position:'absolute',marginTop:'20vh',}}>OUR VISION</text>
      </div>:null}
      <div style={{display:'flex',flexDirection:button?'row':'column',alignItems:button?'flex-end':'',marginTop:button?'15vh':'',justifyContent:'center'}}>
      <img  style={{height:button?'80vh':'50vh',width:button?'30vw':'92%',margin:button?0:'4%',marginRight:button?'2%':''}} src={l9}/>
      {button?null:<text style={{marginLeft:'4%',fontSize:30,paddingTop:25}}>Our Vision</text>}
      <text style={{fontSize:14,color:'#1d1c18',width:button?'70vh':'',margin:'4%',paddingBottom:button?'':'15vw',lineHeight:1.7}}>We aim to be the go-to choice for premium doors that not only enhance the visual appeal of spaces but also provide unmatched functionality and security. Striving to set the standard for excellence in the industry and continue to be a trusted partner for architects, builders, and homeowners alike.</text>
      </div>
    </div>
 
      <div  style={{display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#e6dfda',height:button?'':'60vh'}}>
 <div data-aos="fade-up"   style={{fontFamily:'Canela',textAlign:'center',width:button?'40vw':'',alignItems:'center',display:'flex',margin:button?150:30,flexDirection:'column',}}>
 <img  style={{height:button?'12vh':'',width:button?'15%':'20%'}} src={l4}/>
      <text style={{fontSize:button?'medium':14,color:'#1d1c18',fontWeight:300,paddingBottom:15,paddingTop:25}}>At the heart of our success lies our unwavering commitment to craftsmanship and quality. We source the finest materials to ensure that each door not only meets but exceeds industry standards.</text>
      <text style={{}}>__________________</text>
    </div>
    </div>
   
    <div style={{display:'flex',flexDirection:button?'row':'column',padding:button?'15vh':'6%',}}>
      <text style={{fontSize:24,fontWeight:500,paddingTop:button?'':'5%'}}>SUSTAINABLITY</text>
      <div style={{display:'flex',flexDirection:'column',marginLeft:button?'25vh':''}}>
      <text style={{paddingTop:button?'':'1%',fontSize:button?'':14}}>OUR COMMITMENT</text>
        <text style={{fontSize:14,color:'gray',marginTop:button?10:15,paddingBottom:button?'':'5%',}}>We are committed to environmental responsibility. The Door Story takes proactive measures to minimize our ecological footprint by employing sustainable practices in our manufacturing processes. From responsibly sourced materials to eco-friendly finishes, we strive to contribute positively to the environment.</text>
      </div>
    </div>
     
    <img  style={{height:button?'40vh':'15vh',width:button?'100%':'100%'}} src={wood}/>
 
    <div style={{display:'flex',backgroundColor:'#383736',paddingTop:button?'25vh':'15vh',alignItems:'center',justifyContent:'center',color:'white',fontFamily:'Inter',paddingBottom:button?'25vh':'15vh',flexDirection:'column'}}>
    <text style={{fontSize:button?30:24,width:button?'30%':'80%',textAlign:'center',lineHeight:1.3,marginBottom:button?'10vh':'5vh'}}>Discover the most popular types of doors</text>
    <text style={{backgroundColor:'white',height:40,borderRadius:25,textAlign:'center',color:'black',fontSize:12,paddingTop:11,paddingLeft:25,paddingRight:25}}>Customize to match your vision</text>
  {button ?<div style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',alignItems:'center',marginTop:'10vh',textAlign:'center',width:'100%'}}>
      <div style={{display:'flex',flexDirection:'column',width:'20%',alignItems:'center'}}>
      <img  style={{height:button?110:'20vh',width:button?200:'100%',marginBottom:10}} src={td1}/>
        <text style={{fontSize:14,color:'lightgray'}}>Single Swing Door</text>
      </div>
      <div style={{display:'flex',flexDirection:'column',width:'20%',alignItems:'center'}}>
      <img  style={{height:button?110:'20vh',width:button?200:'100%',marginBottom:10}} src={td4}/>
        <text style={{fontSize:14,color:'lightgray'}}>Surface Sliding Door</text>
      </div>
      <div style={{display:'flex',flexDirection:'column',width:'20%',alignItems:'center'}}>
      <img  style={{height:button?110:'20vh',width:button?200:'100%',marginBottom:10}} src={td3}/>
        <text style={{fontSize:14,color:'lightgray'}}>Pocket Door</text>
      </div>
      <div style={{display:'flex',flexDirection:'column',width:'20%',alignItems:'center'}}>
      <img  style={{height:button?110:'20vh',width:button?200:'100%',marginBottom:10}} src={td2}/>
        <text style={{fontSize:14,color:'lightgray'}}>Double Swing Door</text>
      </div>
    </div>:
    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',alignItems:'center',marginTop:'10vh',}}>
          <div style={{display:'flex',flexDirection:'column'}}>
       <div style={{display:'flex',flexDirection:'column',width:button?'20%':'',alignItems:'center'}}>
      <img  style={{height:90,width:button?200:'',marginBottom:10}} src={td1}/>
        <text style={{fontSize:14,color:'lightgray'}}>Single Swing Door</text>
      </div>
      <div style={{display:'flex',flexDirection:'column',width:button?'20%':'',alignItems:'center'}}>
      <img  style={{height:90,width:button?200:'',marginBottom:10}} src={td4}/>
        <text style={{fontSize:14,color:'lightgray'}}>Surface Sliding Door</text>
      </div>
      </div>
      <div style={{display:'flex',flexDirection:'column'}}>
      <div style={{display:'flex',flexDirection:'column',width:button?'20%':'',alignItems:'center'}}>
      <img  style={{height:90,width:button?200:'',marginBottom:10}} src={td3}/>
        <text style={{fontSize:14,color:'lightgray'}}>Pocket Door</text>
      </div>
      <div style={{display:'flex',flexDirection:'column',width:button?'20%':'',alignItems:'center'}}>
      <img  style={{height:90,width:button?200:'',marginBottom:10}} src={td2}/>
        <text style={{fontSize:14,color:'lightgray'}}>Double Swing Door</text>
      </div>
      </div>
      </div>
}
    </div>
   
    <div style={{display:'flex',flexDirection:button?'row':'column',fontFamily:'Montserrat',backgroundColor:'#f2f2f1',alignItems:'center',padding:button?'15vh':''}}>
      <img  style={{height:button?'70vh':'50vh',width:button?'':'100%',}} src={l5}/>
      <div style={{display:'flex',flexDirection:'column',margin:button?'10vw':'5%',fontWeight:600,}}>
      <text style={{color:'#1d1c18',paddingBottom:button?'':'10vw',paddingTop:button?'':'6vw'}}>From grand entrances to intricately designed interior doors, our portfolio reflects the diversity of our creations. Discover doors that transcend functionality, becoming unique expressions of style.</text>

      </div>
    </div>
    
    <div style={{display:'flex',flexDirection:button?'row':'column-reverse',justifyContent:'space-between',alignItems:'center'}}>
      <div style={{width:button?'50%':'100%',display:'flex',flexDirection:'column',justifyContent:'center',fontFamily:'Montserrat',padding:button?'10vh':'5%'}}>
          <text style={{fontSize:24,paddingTop:button?'':'5vw'}}>Innovation and Design</text>
          <text style={{fontSize:14,paddingTop:25,lineHeight:1.7,paddingRight:button?'10vw':'5%',paddingBottom:button?'':'20%'}}>
In a world where design trends evolve rapidly, we stay ahead of the curve by fostering a culture of innovation. Our design team continually explores new concepts and materials to create doors that blend seamlessly with modern architectural styles.</text>

      </div>
      <img  style={{height:button?'100vh':'50vh',width:button?'50%':'90%',paddingTop:button?'':'5%'}} src={bg}/>
    </div>

<div style={{display:'flex',backgroundColor:'#fafafa',flexDirection:'column',fontFamily:'Montserrat',backgroundColor:'#f2f2f1'}}>
    <div style={{paddingTop:button?'20vh':'10vh'}}>
        <text style={{fontSize:30,marginLeft:button?'25vw':'15vw',}}><span style={{color:'lightGrey'}}>OUR</span> WORK</text>
        <div style={{borderTop:'1px solid black',width:'31%',marginTop:10}}></div>
      </div>
      <div style={{display:'flex',flexDirection:button?'row':'column',justifyContent:button?'flex-end':'flex-start',margin:button?'5vw':'15vw',alignItems:button?'flex-end':'',marginTop:'15vh'}}>
      <text style={{marginRight:45,fontSize:14,width:button?'25vw':'70vw',marginBottom:button?'':'15vw'}}>With every door we create, we embark on a creative adventure, combining innovation and craftsmanship.</text>
      {button?<img  style={{height:button?'15vw':'50vh',width:button?'15vw':'80%',marginRight:15}} src={l11}/>:null}
      <img  style={{height:button?'25vh':'25vh',width:button?'20vw':'85vw',marginRight:button?15:''}} src={l8}/>
      {button?<img  style={{height:button?'40vh':'50vh',width:button?'15vw':'100%'}} src={l9}/>:null}
      {button?null:<a href='/projects' className='endtext' style={{width:94}}>Explore More</a>}
      </div>
      {button?<text style={{marginTop:'20vh',marginRight:'10vh',fontSize:40,fontWeight:800,fontFamily:'Montserrat',textAlign:'right',marginBottom:'5vh'}}>The Door Story</text>:null}
   
    </div>
    <Footer/>
    </div>
  );
}

export default Home;