import './App.css';
import React, { useState, useEffect} from 'react'
import  Navigation  from "./Navbar.js";

import { Routes, Route,BrowserRouter } from "react-router-dom";
import Home from './Components/Home';
import About from './Components/AboutUs.js';
import Contact from './Components/Contact.js';
import Projects from './Components/Projects.js';


const  App=()=> {

const [button,setButton] = useState(true);

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
},[]);
  window.addEventListener('resize',showButton);

  return <div style={{flex:1,}}>  
<BrowserRouter>
<Navigation/>
<Routes>
<Route path='/' element={<Home/>} />
<Route path='/projects' element={<Projects/>} />
<Route path='/about' element={<About/>} />
<Route path='/contact' element={<Contact/>} />
</Routes>


</BrowserRouter>
  </div>
}

export default App;
